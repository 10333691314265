import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';
import { handleGeolocationError } from './helpers/handle_geolocation_error';
import { useElementVisibility } from './mixins/useElementVisibility';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    path: String,
    parent: String,
    allowClear: true,
  };

  connect() {
    useElementVisibility(this);

    this.configureSelect2();

    this.getPosition()
      .then((position) => {
        this.setPosition(position);
      })
      .catch((positionError) => {
        handleGeolocationError(positionError, false);
      });
  }

  #getLocationSearchUrl() {
    let path = this.pathValue;
    if (!this.isPositionSet) {
      return path;
    }

    // If we've got geolocation then we can pass the lat and lng through to
    // the backend to help narrow down results
    path += `?lat=${this.lat}&lng=${this.lng}`;
    return path;
  }

  configureSelect2() {
    const target = $(this.inputTarget);
    const getLocationSearchUrl = this.#getLocationSearchUrl.bind(this);

    target.select2({
      ajax: {
        url: () => getLocationSearchUrl(),
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term,
            page: params.page,
          };
        },
        processResults: function (response) {
          response.data.forEach((resource) => {
            resource.text =
              resource?.attributes?.text ||
              resource?.attributes?.name ||
              resource.name;
            resource.id = JSON.stringify({
              type: resource.type,
              id: resource.id,
            });
          });

          return {
            results: response.data,
            pagination: {
              more: response.links?.next,
            },
          };
        },
      },
      placeholder: target.attr('placeholder'),
      allowClear: this.allowClearValue,
      closeOnSelect: true,
      width: 'style',
      // parent element to ensure focus isn't stolen by off-canvas or a modal
      dropdownParent: $(this.parentValue),
    });

    $(this.inputTarget).on(
      'select2:select select2:unselect select2:clear',
      function () {
        const event = new Event('change', { bubbles: true });
        this.dispatchEvent(event);
      }
    );

    // Seems to be a bug with select2 and jquery at the moment so it's not autofocusing the input
    // https://github.com/select2/select2/issues/5993
    $(this.inputTarget).on('select2:open', () => {
      document.querySelector('input.select2-search__field').focus();
    });
  }

  getPosition() {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject)
    );
  }

  setPosition(position) {
    this.lat = position.coords.latitude;
    this.lng = position.coords.longitude;
  }

  get isPositionSet() {
    return this.lat && this.lng;
  }
}
